<template>
  <header class="header-area">
    <div class="header-top">
      <img class="banner" src="/assets/img/app/banner1.png" alt="Bonauf">
      <div class="container logo-wrapper">
         <figure class="logo"  @click="gotoHome">
          <img src="/assets/img/app/logo-desktop.png" alt="Bonauf">
        </figure>
        <div class="forminputbox modal-link" v-if="!expand" @click="openOptionPanel">
          לאן תרצו לטוס הפעם?
          <i class="fa-solid fa-angle-down" v-if="!showInitAllOption"></i>
          <i class="fa-solid fa-xmark" v-if="showInitAllOption" @click="initializeAllFilterOption"></i>
        </div>
      </div>
    </div>
    <div class="header-body" v-if="expand">
      <div class="container">
        <div class="row g-3">
          <div ></div>
          <div class="pb-1 px-1 col-lg-3 col-md-3">
            <div class="form-group" @click="openOptionPanel">
              <b-form-select
                class="form-control"
                v-model="selectedDestination"
                :options="optionsDestination"
                :disabled="!expand"
              />
              <div :class="[!expand ? 'collapsed': 'open', 'close' ]">
                <i class="fa-solid fa-xmark mr-2" v-if="!expand && showInitAllOption" @click="initializeAllFilterOption"></i>
                <i class="fa-solid fa-xmark" v-else-if="showInitDestination" @click="initializeDestOption"></i>
              </div>
            </div>
          </div>
          <div class="pb-1 px-1 col-lg-2 col-md-2" v-if="expand">
            <div class="form-group">
              <b-form-select
                class="form-control"
                v-model="selectedMonth"
                :options="optionsMonth"
              />
              <div class="close" v-if="showInitMonth">
                <i class="fa-solid fa-xmark" @click="initializeMonthOption"></i>
              </div>
            </div>
          </div>
          <div class="pb-1 px-1 col-lg-2 col-md-2" v-if="expand">
            <div class="form-group">
              <b-form-select
                class="form-control"
                v-model="selectedDealCategory"
                :options="optionsCategory"
              />
              <div class="close" v-if="showInitCategory">
                <i class="fa-solid fa-xmark" @click="initializeCategoryOption"></i>
              </div>
            </div>
          </div>
          <div class="pb-1 px-1 col-lg-3 col-md-3" v-if="expand">
            <div class="form-group d-flex">
              <div class="form-group col-6 px-0 pl-1">
                <input v-model="fromPrice" class="form-control" :placeholder="`ממחיר (${rangePriceDeal.min})`" />
              </div>
              <div class="form-group col-6 px-0">
                <input v-model="toPrice" class="form-control" :placeholder="`עד מחיר (${rangePriceDeal.max})`" />
              </div>
            </div>
          </div>
          <div class="pb-1 px-1 col-lg-2 col-md-2" v-if="expand">
            <div class="text-center">
              <button class="btn" @click="filterDeals">בואו נעוף</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue';
import TemplateFilterOptionPanel from './TemplateFilterOptionPanel';

export default {
  name: 'FilterOptionPanel',
  components: {
    BFormSelect,
  },
  extends: TemplateFilterOptionPanel,
};
</script>

<style lang="less" scoped>
  .bonauf-desktop {
    .header-area {
      padding: 0px;
    }
  }
</style>
